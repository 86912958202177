import "bootstrap/dist/css/bootstrap.min.css";
import getUnixTime from "date-fns/getUnixTime";
import memoize from "lodash/memoize";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Table } from "react-bootstrap";
import * as api from "../api/api";
import { DateRange } from "../common/DateRange";
import { CountryEntryType } from "../types/CountryEntryType";
import { Event } from "../types/Event";
import { OrderType } from "../types/IndynumberOrderType";
import { LogType } from "../types/LogType";
import { OrderStatuses } from "../types/OrderStatuses";
import { ServiceEntryType } from "../types/ServiceEntryType";
import { getFormattedDateTime } from "../util/getFormattedDateTime";


interface ActivityProps
{
	userId?: number
}

interface Filters {
	phone: string;
	status: number | null;
	dateFrom: Date | null;
	dateTo: Date | null;
}


// ***************************************************************************************************
// ***************************************************************************************************
export function Activity( props: ActivityProps )
{
	const [ userId, setUserId ] = useState<number | null>( props?.userId || null );
	const [ orders, setOrders ] = useState<OrderType[]>( [] );
	const [ countries, setCountries ] = useState<CountryEntryType[]>( [] );
	const [ services, setServices ] = useState<ServiceEntryType[]>( [] );

	const [filters, setFilters] = useState<Filters>({
		phone: "",
		status: null,
		dateFrom: null,
		dateTo: null
	});

	const [ isFilterReset, setIsFilterReset ] = useState<boolean>( false );

	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [pageSize, setPageSize] = useState(10); // Default page size
	const [totalItems, setTotalItems] = useState(0); // Total number of items

	const getCountryTitleById = memoize( ( countryId ) => countries.find( ( element ) => element.id === countryId )?.title || "" );
	const getServiceTitleById = memoize( ( serviceId ) => services.find( ( element ) => element.id === serviceId )?.title || "" );

	useEffect( () =>
	{
		async function loadInitialData()
		{
			await Promise.all(
			[
				updateServicesList(),
				updateCountriesList(),
				updateOrders()
			] );
		}

		loadInitialData();
	}, [] );

	const updateServicesList = async () =>
	{
		const servicesList = await api.getServicesList();
		setServices( servicesList );
	}

	const updateCountriesList = async () =>
	{
		const countriesList = await api.getCountriesList();
		setCountries( countriesList );
	}

	useEffect(() => {
		updateOrders();
	  }, [filters, page, pageSize]);

	// useEffect( () =>
	// {
	// 	if( !isFilterReset || phoneFilter || statusFilter || dateFromFilter || dateToFilter )
	// 		return;

	// 	setIsFilterReset( false );
	// 	updateOrders();
	// },
	// [ phoneFilter, statusFilter, dateFromFilter, dateToFilter ])


	const updateOrders = async ( loadInfilteredDate: boolean = false ) =>
	{
		setLoading( true );

		try
		{
			const filtersPayload = loadInfilteredDate ?
			{
				userId,
				phone: "",
				status: null,
				dateFromTimestamp: null,
				dateToTimestamp: null,
				limit: pageSize,
				offset: (page - 1) * pageSize
			} :
			{
				userId,
				phone: filters.phone,
				status: filters.status,
				dateFromTimestamp: filters.dateFrom && getUnixTime(filters.dateFrom),
				dateToTimestamp: filters.dateTo && getUnixTime(filters.dateTo),
				limit: pageSize,
				offset: (page - 1) * pageSize
			};

			const { orders, totalCount } = await api.getOrders( filtersPayload );

			setTotalItems(totalCount);
			setOrders( orders );
		}
		catch( error )
		{
			console.error( error );
		}

		setLoading( false );
	}


	// ***************************************************************************************************
	// ***************************************************************************************************
	const onSearchStringUpdate = ( event: any ) => setFilters({ ...filters, phone: event.target.value });
	const onSearchStringKeyPress = ( event: any ) =>
		{
			if (event.key === "Enter")
				event.preventDefault();
		}
	const onStatusFilterChange = ( event: any ) => setFilters({ ...filters, status: +event.target.value || null });
	const resetFilter = () =>
		{
			setFilters({
				phone: "",
				status: null,
				dateFrom: null,
				dateTo: null
			});

			updateOrders( true );
		};
	const handlePageChange = (newPage) => {
		setPage(newPage);
		};
	const handlePageSizeChange = (newPageSize) => {
		setPageSize(newPageSize);
		};


	return (
		<>
			<Row style={{ marginBottom: '10px' }}>
				<Col md={ 2 }>
					<Form.Group>
						<Form.Label>Номер телефона</Form.Label>
						<Form.Control
							type="text"
							value={ filters.phone }
							onChange={ onSearchStringUpdate }
							onKeyPress={ onSearchStringKeyPress } />
					</Form.Group>
				</Col>

				<Col md={ 3 }>
					<Form.Group>
						<Form.Label>Статус</Form.Label>
						<Form.Select value={ filters.status || "" } onChange={ onStatusFilterChange }>
							<option value={ "" }></option>
							<option value={ OrderStatuses.PHONE_NUMBER_RELEASED }>Номер освобожден</option>
							<option value={ OrderStatuses.RENT_CANCELLED }>Операция отменена</option>
						</Form.Select>
					</Form.Group>
				</Col>

				<DateRange
					dateFrom={ filters.dateFrom }
					dateTo={ filters.dateTo }
					onDateFromChange={ (date) => setFilters({ ...filters, dateFrom: date }) }
					onDateToChange={ (date) => setFilters({ ...filters, dateTo: date }) } />

				<Col md={ 2 }>
					<Button variant="danger" onClick={ resetFilter } style={{marginTop: 32}}>
						Сбросить фильтр
					</Button>
				</Col>
				<Col md={ 2 }>
					<Button variant="primary" onClick={ () => updateOrders() } style={{marginTop: 32}}>
						Найти
					</Button>
				</Col>
			</Row>

			<Row>
				<Col>
					<PaginationLine
						page={page}
						totalPages={Math.ceil(totalItems / pageSize)}
						pageSize={pageSize}
						handlePageChange={handlePageChange}
						handlePageSizeChange={handlePageSizeChange}
					/>
				</Col>
			</Row>

			<Row>
				<Table striped bordered hover className="marginTop">
					<thead>
						<tr>
							<th>id</th>
							<th>Дата</th>
							<th>ID пользователя</th>
							<th>Провайдер</th>
							<th>ID провайдера</th>
							<th>Телефон</th>
							<th>Страна</th>
							<th>Сервис</th>
							<th>Последний статус</th>
							<th>Цена продажи</th>
							<th>Цена закупки</th>
							<th>Прибыль</th>
						</tr>
					</thead>
					<tbody>
						{
							orders.map( ( order ) =>
								<Order key={ order.id } order={ order } getServiceTitleById={ getServiceTitleById } getCountryTitleById={ getCountryTitleById } /> )
						}
					</tbody>
				</Table>
			</Row>

			<Row style={{ marginBottom: '30px' }}>
				<Col>
					<PaginationLine
						page={page}
						totalPages={Math.ceil(totalItems / pageSize)}
						pageSize={pageSize}
						handlePageChange={handlePageChange}
						handlePageSizeChange={handlePageSizeChange}
					/>
				</Col>
			</Row>
		</>
	);
}


// ***************************************************************************************************
// ***************************************************************************************************
interface OrderProps
{
	order: OrderType,
	getServiceTitleById: ( id: number ) => string,
	getCountryTitleById: ( id: number ) => string
}


// ***************************************************************************************************
// ***************************************************************************************************
function Order( props: OrderProps )
{
	const { order, getServiceTitleById, getCountryTitleById } = props;

	const [ isExpanded, setIsExpanded ] = useState<boolean>( false );
	const [ logs, setLogs ] = useState<LogType[]>( [] );

	useEffect( () => { onExpand() }, [ isExpanded ] );

	const onExpand = async () =>
	{
		if( !isExpanded || logs.length )
			return;

		const logsList = await api.getLogsByOrderId( order.id );
		setLogs( logsList );
	};
	const onRowClick = () => setIsExpanded( !isExpanded );


	return (
		<>
			<tr key={ order.id } onClick={ onRowClick }>
				<td>{ order.id }</td>
				<td>{ getFormattedDateTime( order.date ) }</td>
				<td>{ order.userId }</td>
				<td>{ getProviderTitle( order.providerId ) }</td>
				<td>{ order.providerOperationId }</td>
				<td>{ order.phoneNumber }</td>
				<td>{ getCountryTitleById( order.countryId ) }</td>
				<td>{ getServiceTitleById( order.serviceId ) }</td>
				<td>{ getOrderStringStatus( order.status ) }</td>
				<td>{ order.sellingPrice || "-" }</td>
				<td>{ order.sellingPrice ? order.purchasePrice || "-" : "-" }</td>
				<td>{ order.profit || "-" }</td>
			</tr>
			{
				isExpanded &&
					<>
						<tr>
							<td className="blue" colSpan={ 8 }></td>
						</tr>
						<tr>
							<td colSpan={ 4 }></td>
							<td><strong>Дата</strong></td>
							<td><strong>Событие</strong></td>
							<td><strong>Текст SMS</strong></td>
							<td><strong>IP</strong></td>
						</tr>
						{
							logs.map( ( log ) =>
								<tr key={ log.id }>
									<td colSpan={ 4 }></td>
									<td>{ getFormattedDateTime( log.date ) }</td>
									<td>{ getLogStringEvent( log.event ) }</td>
									<td>{ log.smsText }</td>
									<td>{ log.ip }</td>
								</tr> )
						}
						<tr>
							<td className="blue" colSpan={ 8 }></td>
						</tr>
					</>
			}
		</>
	)
}


interface PaginationLineProps {
	page: number;
	totalPages: number;
	pageSize: number;
	handlePageChange: (newPage: number) => void;
	handlePageSizeChange: (newPageSize: number) => void;
}


// ***************************************************************************************************
// ***************************************************************************************************
const PaginationLine: React.FC<PaginationLineProps> = ({ page, totalPages, pageSize, handlePageChange, handlePageSizeChange }) => {
	return (
		<>
			<Button variant="primary" onClick={() => handlePageChange(page - 1)} disabled={page === 1}>
				Назад
			</Button>
			<span style={{ margin: '0 20px' }}>Страница {page}</span>
			<Button variant="primary" onClick={() => handlePageChange(page + 1)} disabled={page >= totalPages}>
				Вперед
			</Button>
			<div style={{ display: 'inline-block', marginLeft: '20px' }}>

				<Button
					variant={page === 1 ? 'secondary' : 'light'}
					onClick={() => handlePageChange(1)}
					style={{ margin: '0 2px' }}
				>
					1
				</Button>

				{page > 7 && <span>...</span>}

				{[...Array(Math.min(totalPages, 12)).keys()].map((_, index) => {
					const pageNumber = Math.max(2, page - 5) + index;
					if (pageNumber >= totalPages) return null;
					return (
						<Button
							key={pageNumber}
							variant={pageNumber === page ? 'secondary' : 'light'}
							onClick={() => handlePageChange(pageNumber)}
							style={{ margin: '0 2px' }}
						>
							{pageNumber}
						</Button>
					);
				})}

				{page < totalPages - 6 && <span>...</span>}

				{totalPages > 1 && (
					<Button
						variant={page === totalPages ? 'secondary' : 'light'}
						onClick={() => handlePageChange(totalPages)}
						style={{ margin: '0 2px' }}
					>
						{totalPages}
					</Button>
				)}
			</div>
			<Form.Select onChange={(e) => handlePageSizeChange(Number(e.target.value))} value={pageSize} style={{ width: 'auto', display: 'inline-block', marginLeft: '20px' }}>
				<option value={10}>10</option>
				<option value={20}>20</option>
				<option value={50}>50</option>
			</Form.Select>
		</>
	);
};


// ***************************************************************************************************
// ***************************************************************************************************
function getProviderTitle( providerId: number ): string
{
	if( providerId === 1 )
		return "SMS Activate";

	if( providerId === 2 )
		return "5SIM";

	return "Неизвестный провайдер";
}


// ***************************************************************************************************
// ***************************************************************************************************
function getOrderStringStatus( status: OrderStatuses )
{
	if( status === OrderStatuses.PHONE_NUMBER_RENTED )
		return "Номер выдан";

	if( status === OrderStatuses.WAITING_FOR_SMS )
		return "Ожидание SMS";

	if( status === OrderStatuses.SMS_RECEIVED )
		return "SMS получена";

	if( status === OrderStatuses.PHONE_NUMBER_RELEASED )
		return "Номер освобожден";

	if( status === OrderStatuses.RENT_CANCELLED )
		return "Операция отменена";

	return "Неизвестный статус";
}


// ***************************************************************************************************
// ***************************************************************************************************
function getLogStringEvent( event: Event )
{
	if( event === Event.PHONE_NUMBER_RENTED )
		return "Номер выдан";

	if( event === Event.WAITING_FOR_SMS )
		return "Ожидание SMS";

	if( event === Event.OBTAIN_SMS_QUERY )
		return "Запрос на получение SMS";

	if( event === Event.SMS_RECEIVED )
		return "SMS получена";

	if( event === Event.PHONE_NUMBER_RELEASED )
		return "Номер освобожден";

	if( event === Event.RENT_CANCELLED )
		return "Операция отменена";

	return "Неизвестное событие";
}
